import React, { Component, useState } from "react"
import { Script } from "gatsby";
import { Helmet } from "react-helmet";
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { theme } from "../Styles/theme"
import _ from "lodash"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { HeadingTertiary } from "../Styles/headers"
import { SectionHero, SectionTagLine } from "../Styles/text"
import { Section } from "../Styles/section"
import { InlineWidget } from "react-calendly";

const HeaderSection = styled.div`
  color: ${theme.colors.grey900};
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  margin-top: 5rem;
  line-height: 1.4;
  position: relative;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: calc(${props => props.theme.sizes.navbarHeight} + 1.5rem);
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    h3 {
      line-height: 1.4;
    }
  }
`

const BtnMedium = css`
  background: ${theme.colors.primary};
  padding: ${theme.pm.pm100} ${theme.pm.pm200};
  color: #fff;
  display: inline-block;
  border: none;
  font-size: ${theme.text.text200};
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`

// const iframe = css`
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   border: 0; 
// `;



export default function NewCoachingHeader() {
  return (
    <Section backgroundColor="main" style={{ marginTop: "-2rem", fontFamily: "libre baskerville" }}>
      <HeaderSection>
        <HeadingTertiary style={{ margin: "0px 0 4px", textAlign: "center" }}>
          Book a call to get my direct help.
        </HeadingTertiary>
        {/* <div>
            1. Committed learners - Land your first dev role
            <ul>
              <li>- Must already know all the fundamentals and have built projects</li>
              <li>- 3-6 months of exp under the belt </li>
              <li>- Committed learners with runway, time, and energy to pursue this to the finish line.</li>
            </ul>
          </div>
          <div>
            2. Seasoned professionals - Freelancing or creating a personal brand
            <ul>
              <li>- Must have easily verifiable expertise or experience</li>
              <li>- Land your first $10k+ deal</li>
              <li>- Building a personal brand (Youtube) and setting up backend systems for a sustainability without losing your sanity</li>
            </ul>
          </div> */}
        {/* <Link css={CtaMedium} to="/community">
          Learn More
        </Link> */}
        <br />
        {/* <iframe css={iframe} data-tally-src="https://tally.so/r/w5jxOo?transparentBackground=1"  style={{ width:"100%", height: "100%", frameBorder: "0", marginHeight: "0", marginWidth: "0" }} title="Book a call to get my direct help"></iframe> */}


        <InlineWidget url="https://calendly.com/tenzin/work-with-me" styles={{height: '1000px', marginTop: "-2rem"}} />
      </HeaderSection>
    </Section>
  )
}
