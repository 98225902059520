import React from "react"
import { theme } from "../Styles/theme"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import Header from "../Components/header"
import LandingLayout from "../Components/landingLayout"
import Brands from "../Components/brands"
import Cta from "../Components/cta"
import { GlobalStyles } from "../Styles/global"
import NewCoachingHeader from "../Components/new-coaching-header";

export default () => (
  <ThemeProvider theme={theme}>
    <Global styles={GlobalStyles} />
    <LandingLayout>
        <NewCoachingHeader />
    </LandingLayout>
  </ThemeProvider>
)